"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLocationNotifications = exports.getPracticeLocations = exports.refundLoan = exports.cancelLoan = exports.adjustLoan = exports.getManagerById = exports.updateManager = exports.newManager = exports.getManagers = exports.getReconciliationReport = exports.getCreditAvailableReport = exports.getSalesReport = exports.getApplicationsReport = exports.refundRequest = exports.voidSale = exports.fetchLocationPricingTiers = exports.fetchPlansByLocationId = exports.salesRequest = exports.fetchLocationDetails = exports.fetchAllLocationsForManager = exports.searchForAccounts = exports.applyForCredit = exports.newMerchantCreditApplication = exports.submitFullApplication = exports.getCreditApplicationIdentityVerification = exports.createCreditApplicationIdentityVerification = exports.prequalifyCreditApplication = exports.prequalifyWithNextLender = exports.patchApplicant = exports.searchForPractices = exports.requestPrequalification = exports.newCreditApplication = exports.applyAfterPrequalification = exports.signConsumerReceipt = exports.getReceiptPDF = exports.getReceipt = exports.validateSSN = exports.getCreditApplicationByTokenValidate = exports.getCreditApplicationByToken = exports.getCreditApplicationsByStatus = exports.getLenderSettings = exports.updateProposedEnrollmentFileChanges = exports.updateProposedEnrollmentDataChanges = exports.createProposedEnrollmentDataChanges = exports.getBankName = exports.getProposedEnrollmentDataChanges = exports.getNewDealTerms = exports.signNewDealTerms = exports.uploadDocument = exports.addAuthorizedSigner = void 0;
exports.affContractStatus = exports.ugaContractStatus = exports.getReceiptOptions = exports.createCalculatorPDF = exports.getAccountVerification = exports.requestAccountVerification = exports.createPushApplication = exports.getSalesReceiptSigned = exports.getSalesReceipt = exports.sendExternalReferencesCommunication = exports.createReceipt = exports.sendReceipt = exports.signReceiptV2 = exports.signReceipt = exports.getLenderTerms = exports.updateLocationPlans = void 0;
//@ts-nocheck
const lp_redux_api_1 = require("lp-redux-api");
const utils_1 = require("utils");
exports.addAuthorizedSigner = (0, lp_redux_api_1.createPostRequest)('ADD_AUTHORIZED_SIGNER', (values, locationId, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/forms/submit/by_name`,
    body: {
        formName: 'location_authorized_signer',
        values,
        locationId,
    },
}));
function uploadDocument(documentData, bearerToken) {
    return async function () {
        const formData = new FormData();
        formData.append('file', documentData.file);
        formData.append('content', documentData.content);
        documentData.name && formData.append('name', documentData.name);
        documentData.comment && formData.append('comment', documentData.comment);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/merchant_management/v1/files`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
            body: formData,
        });
        return response.json();
    };
}
exports.uploadDocument = uploadDocument;
exports.signNewDealTerms = (0, lp_redux_api_1.createPostRequest)('SIGN_NEW_DEAL_TERMS', (id, body, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/locations/${id}/terms/sign`,
    body: body,
}));
exports.getNewDealTerms = (0, lp_redux_api_1.createGetRequest)('GET_NEW_DEAL_TERMS', (id, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/locations/${id}/terms?signed=false`,
}));
exports.getProposedEnrollmentDataChanges = (0, lp_redux_api_1.createGetRequest)('GET_PROPOSED_ENROLLMENT_DATA_CHANGES', (id, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/locations/${id}/proposed_changes`,
}));
exports.getBankName = (0, lp_redux_api_1.createGetRequest)('GET_BANK_NAME', (bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/bank_name_lookup`,
}));
exports.createProposedEnrollmentDataChanges = (0, lp_redux_api_1.createPostRequest)('CREATE_PROPOSED_ENROLLMENT_DATA_CHANGES', (id, proposedChanges, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/locations/${id}/proposed_changes`,
    body: proposedChanges,
}));
exports.updateProposedEnrollmentDataChanges = (0, lp_redux_api_1.createPatchRequest)('UPDATE_PROPOSED_ENROLLMENT_DATA_CHANGES', (id, proposedChangeId, proposedChanges, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/locations/${id}/proposed_changes/${proposedChangeId}`,
    body: proposedChanges,
}));
exports.updateProposedEnrollmentFileChanges = (0, lp_redux_api_1.createPatchRequest)('UPDATE_PROPOSED_ENROLLMENT_FILE_CHANGES', (id, proposedChangeId, proposedChanges, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/locations/${id}/proposed_changes/${proposedChangeId}/files`,
    body: proposedChanges,
}));
exports.getLenderSettings = (0, lp_redux_api_1.createGetRequest)('GET_LENDER_SETTINGS', (lenderName, locationId, bearerToken) => ({
    bearerToken,
    url: (0, utils_1.makeQueryPath)(`/merchant_management/v1/lender_settings/${lenderName}`, locationId ? { locationId: locationId } : {}),
}));
exports.getCreditApplicationsByStatus = (0, lp_redux_api_1.createGetRequest)('GET_CREDIT_APPLICATIONS_BY_STATUS', (applicationSearchCriteria, status) => ({
    url: `/consumer_applications/v1/credit_applications/status/${status}`,
    query: applicationSearchCriteria,
}));
exports.getCreditApplicationByToken = (0, lp_redux_api_1.createGetRequest)('GET_CREDIT_APPLICATION_BY_TOKEN', (applicationSearchCriteria, token) => ({
    url: `/consumer_applications/v1/credit_applications/token/${token}`,
    query: applicationSearchCriteria,
}));
exports.getCreditApplicationByTokenValidate = (0, lp_redux_api_1.createGetRequest)('GET_CREDIT_APPLICATION_BY_TOKEN_VALIDATE', (token) => ({
    url: `/consumer_applications/v1/credit_applications/token/${token}/validate`,
}));
exports.validateSSN = (0, lp_redux_api_1.createPostRequest)('VALIDATE_SSN', (resourceId, resourceType, validationType, validationInput) => ({
    url: '/consumer/v2/auth/validate',
    body: {
        resourceId,
        resourceType,
        validationType,
        validationInput,
    },
}));
exports.getReceipt = (0, lp_redux_api_1.createGetRequest)('GET_RECEIPT', (receiptId, bearerToken) => ({
    bearerToken,
    url: `/consumer/v2/receipts/${receiptId}`,
}));
function getReceiptPDF(receiptId, bearerToken) {
    return async function () {
        const receiptPdf = await fetch(`${process.env.REACT_APP_API_URL}/consumer/v2/receipts/${receiptId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
                Authorization: `Bearer ${bearerToken}`,
            },
        });
        const receiptBlob = await receiptPdf.blob();
        return window.URL.createObjectURL(receiptBlob);
    };
}
exports.getReceiptPDF = getReceiptPDF;
exports.signConsumerReceipt = (0, lp_redux_api_1.createPatchRequest)('SIGN_RECEIPT', (signReceiptData, bearerToken) => ({
    bearerToken,
    url: `/consumer/v2/receipts/${signReceiptData.receiptUuid}/sign`,
    body: signReceiptData,
}));
exports.applyAfterPrequalification = (0, lp_redux_api_1.createPostRequest)('APPLY_AFTER_PREQUALIFICATION', (application) => ({
    url: '/consumer_applications/v1/credit_applications/apply_after_prequalification',
    body: application,
}));
exports.newCreditApplication = (0, lp_redux_api_1.createGetRequest)('NEW_CREDIT_APPLICATION', (location_id, language = 'en') => ({
    url: `/consumer_applications/v1/prequalifications/new`,
    query: {
        location_id,
        language,
    },
}));
exports.requestPrequalification = (0, lp_redux_api_1.createPostRequest)('REQUEST_PREQUALIFICATION', (prequalificationData) => ({
    url: '/consumer_applications/v1/prequalifications',
    body: prequalificationData,
}));
exports.searchForPractices = (0, lp_redux_api_1.createGetRequest)('PRACTICE_DATA', (ads_store_number, language = 'en') => ({
    url: `/consumer_applications/v1/locations/search`,
    query: {
        ads_store_number: ads_store_number,
        language,
    },
}));
exports.patchApplicant = (0, lp_redux_api_1.createPatchRequest)('PATCH_APPLICANT', (applicattionId, updatedApplicant) => ({
    url: `/consumer_applications/v1/credit_applications/${applicattionId}`,
    body: updatedApplicant,
}));
exports.prequalifyWithNextLender = (0, lp_redux_api_1.createPostRequest)('PREQUALIFY_WITH_NEXT_LENDER', (applicationId, prequalificationData) => ({
    url: `/consumer_applications/v1/prequalifications/${applicationId}/next_lender`,
    body: prequalificationData,
}));
exports.prequalifyCreditApplication = (0, lp_redux_api_1.createPostRequest)('PREQUALIFY_CREDIT_APPLICATION', (applicationId, prequalification) => ({
    url: `/consumer_applications/v1/credit_applications/${applicationId}/prequalify`,
    body: prequalification,
}));
exports.createCreditApplicationIdentityVerification = (0, lp_redux_api_1.createPostRequest)('CREATE_CREDIT_APPLICATION_IDENTITY_VERIFICATION', (creditApplicationId, creditApplicationData) => ({
    url: `/consumer_applications/v1/credit_applications/${creditApplicationId}/identity_verification`,
    body: creditApplicationData,
}));
exports.getCreditApplicationIdentityVerification = (0, lp_redux_api_1.createGetRequest)('GET_CREDIT_APPLICATION_IDENTITY_VERIFICATION', (creditApplicationId) => ({
    url: `/consumer_applications/v1/credit_applications/${creditApplicationId}/identity_verification`,
}));
exports.submitFullApplication = (0, lp_redux_api_1.createPostRequest)('POST_FULL_APPLICATION', (creditApplicationId, creditApplicationData) => ({
    url: `/consumer_applications/v1/credit_applications/${creditApplicationId}/apply`,
    body: creditApplicationData,
}));
//Merchant APIs
exports.newMerchantCreditApplication = (0, lp_redux_api_1.createGetRequest)('MERCHANT_NEW_CREDIT_APPLICATION', (location_id, language, bearerToken) => ({
    bearerToken,
    url: '/merchant_management/v1/credit_applications/new',
    query: {
        location_id,
        language,
    },
}));
exports.applyForCredit = (0, lp_redux_api_1.createPostRequest)('APPLY_FOR_CREDIT', (creditApplicationData, bearerToken) => ({
    bearerToken,
    url: '/merchant_management/v1/credit_applications/apply',
    body: creditApplicationData,
}));
exports.searchForAccounts = (0, lp_redux_api_1.createPostRequest)('ACCOUNT_LIST', (createPostRequest, bearerToken) => ({
    bearerToken,
    url: '/merchant_management/v1/lookups/all_ads_accounts',
    body: createPostRequest,
}));
exports.fetchAllLocationsForManager = (0, lp_redux_api_1.createGetRequest)('FETCH_ALL_LOCATIONS_FOR_MANAGER', (bearerToken) => ({
    bearerToken,
    url: '/merchant_management/v1/locations',
}));
exports.fetchLocationDetails = (0, lp_redux_api_1.createGetRequest)('FETCH_LOCATION_DETAILS', (locationId, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/locations/${locationId}`,
}));
exports.salesRequest = (0, lp_redux_api_1.createPostRequest)('SALES_REQUEST', (salesRequestData, bearerToken) => ({
    bearerToken,
    url: '/merchant_management/v2/transactions/sale',
    body: salesRequestData,
}));
exports.fetchPlansByLocationId = (0, lp_redux_api_1.createGetRequest)('FETCH_PLANS_BY_LOCATION_ID', (locationId, amount, activeOnly = true, bearerToken) => ({
    bearerToken,
    url: (0, utils_1.makeQueryPath)(`/merchant_management/v1/locations/${locationId}/plans`, { amount, activeOnly }),
}));
exports.fetchLocationPricingTiers = (0, lp_redux_api_1.createGetRequest)('FETCH_PRICING_TIERS_BY_LOCATION_ID', (locationId, bearerToken) => ({
    bearerToken,
    url: (0, utils_1.makeQueryPath)(`/merchant_management/v1/locations/${locationId}/pricing_tiers`),
}));
exports.voidSale = (0, lp_redux_api_1.createPatchRequest)('VOID_TRANSACTION', (voidTransactionData, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v2/transactions/void`,
    body: voidTransactionData,
}));
exports.refundRequest = (0, lp_redux_api_1.createPostRequest)('REFUND_REQUEST', (refundRequestData, bearerToken) => ({
    bearerToken,
    url: '/merchant_management/v2/transactions/refund',
    body: refundRequestData,
}));
exports.getApplicationsReport = (0, lp_redux_api_1.createPostRequest)('GET_APPLICATIONS_REPORT', (reportData, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/reports/applications`,
    body: reportData,
}));
exports.getSalesReport = (0, lp_redux_api_1.createPostRequest)('GET_SALES_REPORT', (reportData, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/reports/sales`,
    body: reportData,
}));
exports.getCreditAvailableReport = (0, lp_redux_api_1.createPostRequest)('GET_CREDIT_AVAILABLE_REPORT', (reportData, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/reports/credit_available`,
    body: reportData,
}));
exports.getReconciliationReport = (0, lp_redux_api_1.createPostRequest)('GET_RECONCILIATION_REPORT', (reconciliationReportRequest, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/reports/bank_reconciliation`,
    body: reconciliationReportRequest,
}));
exports.getManagers = (0, lp_redux_api_1.createGetRequest)('GET_MANAGERS', (bearerToken) => ({
    bearerToken,
    url: '/manager_administration/v1/managers/all',
}));
exports.newManager = (0, lp_redux_api_1.createPostRequest)('CREATE_NEW_MANAGER', (managerData, bearerToken) => ({
    bearerToken,
    url: '/manager_administration/v1/managers',
    body: managerData,
}));
exports.updateManager = (0, lp_redux_api_1.createPatchRequest)('UPDATE_MANAGER', (managerData, bearerToken) => ({
    bearerToken,
    url: '/manager_administration/v1/managers',
    body: managerData,
}));
exports.getManagerById = (0, lp_redux_api_1.createGetRequest)('GET_MANAGER_BY_ID', (id, bearerToken) => ({
    bearerToken,
    url: `/manager_administration/v1/managers/${id}`,
}));
exports.adjustLoan = (0, lp_redux_api_1.createPatchRequest)('ADJUST_LOAN', (alphaeonAccountNumber, loanData, location_id, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/access_loans/${alphaeonAccountNumber}`,
    body: loanData,
    query: {
        location_id,
    },
}));
exports.cancelLoan = (0, lp_redux_api_1.createPostRequest)('CANCEL_LOAN', (alphaeonAccountNumber, location_id, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/access_loans/cancel/${alphaeonAccountNumber}`,
    query: {
        location_id,
    },
}));
exports.refundLoan = (0, lp_redux_api_1.createPostRequest)('REFUND_LOAN', (alphaeonAccountNumber, amount, location_id, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/access_loans/refund/${alphaeonAccountNumber}`,
    body: { amount: amount },
    query: {
        location_id,
    },
}));
exports.getPracticeLocations = (0, lp_redux_api_1.createGetRequest)('GET_LOCATIONS', (fields_to_search, search_criteria, limit = 5) => ({
    url: '/consumer_applications/v1/locations/search_full',
    query: {
        search_criteria: search_criteria,
        fields_to_search: fields_to_search,
        limit: limit,
    },
}));
exports.getLocationNotifications = (0, lp_redux_api_1.createGetRequest)('GET_LOCATION_NOTIFICATIONS', (locationId, bearerToken) => ({
    bearerToken,
    url: (0, utils_1.makeQueryPath)(`/merchant_management/v1/locations/${locationId}/notifications`),
}));
exports.updateLocationPlans = (0, lp_redux_api_1.createPatchRequest)('PATCH_LOCATION_PLANS', (locationId, locationPlanChanges, bearerToken) => ({
    bearerToken,
    url: (0, utils_1.makeQueryPath)(`/merchant_management/v1/locations/${locationId}/plans`),
    body: locationPlanChanges,
}));
exports.getLenderTerms = (0, lp_redux_api_1.createGetRequest)('GET_LENDER_TERMS', (locationId, lenderName) => ({
    url: (0, utils_1.makeQueryPath)(`/consumer_applications/v1/locations/${locationId}/lender_terms/${lenderName}`),
}));
exports.signReceipt = (0, lp_redux_api_1.createPatchRequest)('PATCH_SIGN_RECEIPT', (transactionData, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/transactions/receipt/sign`,
    body: transactionData,
}));
exports.signReceiptV2 = (0, lp_redux_api_1.createPatchRequest)('PATCH_SIGN_RECEIPT', (transactionData, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v2/transactions/${transactionData.transactionId}/receipts/${transactionData.receiptId}/sign`,
    body: transactionData,
}));
exports.sendReceipt = (0, lp_redux_api_1.createPostRequest)('POST_SEND_RECEIPT', (transactionId, receiptId, url, phone, email, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v2/transactions/${transactionId}/receipts/${receiptId}/send`,
    body: {
        transactionId,
        receiptId,
        url,
        phone,
        email,
    },
}));
exports.createReceipt = (0, lp_redux_api_1.createPostRequest)('POST_CREATE_RECEIPT', (transactionId, receiptType, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v2/transactions/${transactionId}/receipts`,
    body: {
        transactionId,
        receiptType,
    },
}));
exports.sendExternalReferencesCommunication = (0, lp_redux_api_1.createPostRequest)('POST_EXTERNAL_REFERENCES', (creditApplicationId, requestData, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/credit_applications/${creditApplicationId}/external_references`,
    body: requestData,
}));
function getSalesReceipt(transactionId, bearerToken) {
    return async function () {
        const receiptPdf = await fetch(`${process.env.REACT_APP_API_URL}/merchant_management/v2/transactions/receipt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
                Authorization: `Bearer ${bearerToken}`,
            },
            body: JSON.stringify({ transaction_id: transactionId }),
        });
        const receiptBlob = await receiptPdf.blob();
        return window.URL.createObjectURL(receiptBlob);
    };
}
exports.getSalesReceipt = getSalesReceipt;
function getSalesReceiptSigned(transactionId, bearerToken) {
    return async function () {
        const receiptPdf = await fetch(`${process.env.REACT_APP_API_URL}/merchant_management/v2/transactions/receipt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
                Authorization: `Bearer ${bearerToken}`,
            },
            body: JSON.stringify({ transaction_id: transactionId }),
        });
        const receiptBlob = await receiptPdf.blob();
        return window.URL.createObjectURL(receiptBlob);
    };
}
exports.getSalesReceiptSigned = getSalesReceiptSigned;
exports.createPushApplication = (0, lp_redux_api_1.createPostRequest)('CREATE_PUSH_APPLICATION', (pushApplicationData, bearerToken) => ({
    bearerToken,
    url: '/merchant_management/v1/credit_applications',
    body: pushApplicationData,
}));
exports.requestAccountVerification = (0, lp_redux_api_1.createPostRequest)('REQUEST_ACCOUNT_VERIFICATION', (alphaeonAccountIdentifier, location_id, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/accounts/${alphaeonAccountIdentifier}/identity_verification`,
    body: { send_verification: true, location_id: location_id },
}));
exports.getAccountVerification = (0, lp_redux_api_1.createGetRequest)('GET_ACCOUNT_VERIFICATION', (alphaeonAccountIdentifier, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/accounts/${alphaeonAccountIdentifier}/identity_verification`,
}));
function createCalculatorPDF(calculatorData, acceptType = 'pdf', bearerToken) {
    return async function () {
        const calculatorPDF = await fetch(`${process.env.REACT_APP_API_URL}/merchant_management/v1/calculators/cost_sheet`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: `application/${acceptType}`,
                Authorization: `Bearer ${bearerToken}`,
            },
            body: JSON.stringify(calculatorData),
        });
        if (acceptType === 'pdf') {
            if (!calculatorPDF.ok) {
                return new Error();
            }
        }
        if (acceptType === 'pdf') {
            const calculatorBlob = await calculatorPDF.blob();
            return window.URL.createObjectURL(calculatorBlob);
        }
        else {
            const data = await calculatorPDF.json();
            return data;
        }
    };
}
exports.createCalculatorPDF = createCalculatorPDF;
const getReceiptOptions = (transactionId, bearerToken) => {
    return async function () {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/merchant_management/v2/transactions/${transactionId}/receipts/options`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
        });
        return response.json();
    };
};
exports.getReceiptOptions = getReceiptOptions;
exports.ugaContractStatus = (0, lp_redux_api_1.createPostRequest)('UGA_CONTRACT_STATUS', (lender_identifier, status) => ({
    url: `/uga_webhooks/v1/contracts/update_status`,
    body: { contract_id: lender_identifier, status: status },
}));
exports.affContractStatus = (0, lp_redux_api_1.createPostRequest)('AFF_CONTRACT_STATUS', (lender_identifier, status) => ({
    url: `/aff_webhooks/v1/contracts/update_status`,
    body: {
        appId: lender_identifier,
        signedDocuments: status == 'signing_complete',
        return_account_details: true,
    },
    decamelizeBody: false,
}));
