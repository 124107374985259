"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const redux_1 = require("redux");
const apiActions = __importStar(require("api-actions"));
const components_1 = require("components");
const config_1 = require("config");
const utils_1 = require("utils");
const actions = __importStar(require("../actions"));
const forms_1 = require("../forms");
const reducer_1 = require("../reducer");
function Application({ applyForCredit, clearMerchantApplicationEnvironment, currentLocation, merchantApplicationEnvironment, newMerchantCreditApplication, setApplicationInfo, setLenderReferenceId, currentPermissions, }) {
    const history = (0, react_router_dom_1.useHistory)();
    (0, react_1.useEffect)(() => {
        newMerchantCreditApplication(currentLocation.locationId, 'en');
        return () => clearMerchantApplicationEnvironment();
    }, []);
    const handleSubmit = (applicationValues) => {
        const { alphaeonTerms, lenderTerms } = merchantApplicationEnvironment;
        const approvedTerms = (0, utils_1.approveTerms)(alphaeonTerms, lenderTerms);
        const applicationInfo = {
            locationId: currentLocation.locationId,
            ...applicationValues,
            prerequisiteTermsAccepted: approvedTerms,
        };
        setApplicationInfo(applicationInfo);
        return applyForCredit(applicationInfo);
    };
    const handleSubmitSuccess = ({ applicationStatus, locationServiceProviderPriorities, lenderRequestReferenceId, lenderStatusMessage, }) => {
        setLenderReferenceId(lenderRequestReferenceId);
        if (applicationStatus === 'approved') {
            history.push(config_1.ACCOUNT_CREATED_URL);
            return;
        }
        else if (applicationStatus === 'account_exists') {
            history.push(config_1.MERCHANT_ACCOUNT_EXISTS_URL);
            return;
        }
        else if (applicationStatus === 'pending' &&
            lenderStatusMessage === config_1.BREAD_LENDER_STATUS.STEP_UP_NEEDED) {
            history.push(config_1.MERCHANT_STEP_UP_URL);
            return;
        }
        else if (applicationStatus === 'pending') {
            history.push(config_1.MERCHANT_PENDING_URL);
            return;
        }
        else {
            const locationOffersAccessLoans = Boolean(locationServiceProviderPriorities?.consumer?.some(({ productType }) => productType === config_1.PRODUCT_TYPE.ACCESS_LOAN));
            const next_path = locationOffersAccessLoans
                ? config_1.MERCHANT_DECLINED_ACCESS_LOAN_AVAILABLE
                : config_1.MERCHANT_DECLINED_URL;
            history.push({
                pathname: next_path,
                state: {
                    showConsumerPortalLink: locationOffersAccessLoans,
                },
            });
            return;
        }
    };
    const dashboardAppEnabledForState = !config_1.STATES_WITH_DASHBOARD_APPS_DISABLED.includes(currentLocation.address?.state);
    if (!dashboardAppEnabledForState ||
        !(0, utils_1.operationPermitted)(currentPermissions, config_1.PERMISSION.SUBMIT_DASHBOARD_APPLICATIONS)) {
        history.push(config_1.ACCOUNT_FULL_APPLICATION_CONFIRM_URL);
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    }
    return ((0, jsx_runtime_1.jsxs)("div", { className: "account-lookup full-application m-t-50", children: [(0, jsx_runtime_1.jsx)("h1", { children: "Full Application" }), (0, jsx_runtime_1.jsxs)("div", { className: "full-application__content m-t-20", children: ["This application will result in a hard credit check. Patients who would like to pre-qualify without impacting their credit should visit", ' ', (0, jsx_runtime_1.jsx)(components_1.ExternalLink, { href: "https://goalphaeon.com/", children: "goalphaeon.com" })] }), (0, jsx_runtime_1.jsx)(forms_1.FullApplicationForm, { onSubmit: handleSubmit, onSubmitSuccess: handleSubmitSuccess, onSubmitFail: utils_1.flashSubmitFailure, applicationEnvironment: merchantApplicationEnvironment })] }));
}
function mapStateToProps(state) {
    return {
        currentLocation: reducer_1.selectors.currentLocation(state),
        merchantApplicationEnvironment: reducer_1.selectors.merchantApplicationEnvironment(state),
        currentPermissions: reducer_1.selectors.currentPermissions(state),
    };
}
const mapDispatchToProps = {
    setApplicationInfo: actions.setApplicationInfo,
    setLenderReferenceId: actions.setLenderReferenceId,
    clearMerchantApplicationEnvironment: actions.clearMerchantApplicationEnvironment,
};
const mapApiAuthToProps = {
    applyForCredit: apiActions.applyForCredit,
    newMerchantCreditApplication: apiActions.newMerchantCreditApplication,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps), (0, utils_1.withApiAuth)(mapApiAuthToProps))(Application);
